


// Real Routing

import React from "react";
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import Home from "./Home";
import Aboutnew from "./Aboutnew";
import Contactnew from "./Contactnew";
  
function App() {
  
    return (
        <>
            <BrowserRouter>
                <div style={{
                    display: "flex",
                    background: 'black',
                    padding: '5px 0 5px 5px',
                    fontSize: '20px'
                }}>
                    <div style={{ margin: '10px' }}>
                        <NavLink to="/" style={({ isActive }) => ({ 
                            color: isActive ? 'greenyellow' : 'white' })}>
                            Home
                        </NavLink>
                    </div>
                    <div style={{ margin: '10px' }}>
                        <NavLink to="/About" style={({ isActive }) => ({ 
                            color: isActive ? 'greenyellow' : 'white' })}>
                            About
                        </NavLink>
                    </div>
                    <div style={{ margin: '10px' }}>
                        <NavLink to="/Contact" style={({ isActive }) => ({ 
                            color: isActive ? 'greenyellow' : 'white' })}>
                            Contact
                        </NavLink>
                    </div>
                </div>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/About" element={<Aboutnew />} />
                    <Route exact path="/Contact" element={<Contactnew />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}
  
export default App;




// Real Routing 

// import React from 'react';
// import './App.css';
// import { BrowserRouter } from "react-router-dom";
// import { Routes, Route } from "react-router-dom";
// import { Link } from "react-router-dom";
// import Home from './Home';
// import About from './About';
// import Contact from './Contact';


// function App() {
//   return (
//     <BrowserRouter>
//     <div className="App">
    
//       <Routes>
//       <Link to="/">Home</Link>
//     <Link to="/about">About</Link> 
//     <Link to="/contact">Contact</Link> 
//         <Route path="/" element={ <Home/> } />
//         <Route path="about" element={ <About/> } />
//         <Route path="contact" element={ <Contact/> } />
//       </Routes>
     
//     </div>
//     </BrowserRouter>
//   )
// }

// export default App;



// // simple Single Page Application (SPA) Routing basic

// import React from 'react';
// import './App.css';
// import { BrowserRouter } from "react-router-dom";
// import { Routes, Route } from "react-router-dom";
// import { Link } from "react-router-dom";
// import Home from './Home';
// import About from './About';
// import Contact from './Contact';



// function App() {
//   return (
//     <BrowserRouter>
//     <div className="App">
//       <nav>
//       <ul>
//         <li><Link to="/">Home</Link></li>
//         <li><Link to="/about">About</Link></li>
//       <li><Link to="/contact">Contact</Link></li>
 
  
//       </ul>
//       </nav>
      
//       <Routes>

//         <Route path="/" element={ <Home/> } />
//         <Route path="/about" element={ <About/> } />
//         <Route path="/contact" element={ <Contact/> } />
//       </Routes>
     
//     </div>
//     </BrowserRouter>
//   )
// }

// export default App;














// // High Order Comonents HOC component

// import './App.css';
// import React, {useRef, useState} from 'react';

// function App()

// {
//   return(
//     <div className="App">
//   <h1>HOC</h1>
//   <HOCred cpm={Counter} />
//   <HOCblue cpm={Counter} />
//   </div>
//   );
// }

// function HOCred(props)
// {
//   return<h1 style={{color:'red'}}><props.cpm /></h1>
// }
// function HOCblue(props)
// {
//   return<h1 style={{color:'blue'}}><props.cpm /></h1>
// }

// function Counter()
// {
//   const [count,setCount]=useState(0)
//     return <div>
   
//       <span>{count}</span><br />
//      <button onClick={()=>setCount(count+1)}>Update</button>
        
//       </div>
    
// }


// export default App;





// // Use Ref in react class component

// import './App.css';
// import React, {useRef} from 'react';

// function App()

// {

// let inputRef=useRef(null)

// function handleInput()
// {
//   console.warn("handle works")
//   inputRef.current.value="5000";
//   inputRef.current.focus();
// }

 
//     return (
   
//       <div className="App">
//       <header className="App-header">
//     <h1>React learning for Ref  </h1>
//       <h2>Count</h2>
//       <input type="text" ref={inputRef}  />
//      <button onClick={()=>handleInput()}>Get Value</button>
//        </header>   
//       </div>
   
  
//    )
  

// }


// export default App;


// // Use Ref in react class component

// import './App.css';
// import React, {createRef} from 'react';

// class App extends React.Component{
 
//   constructor()
// {
//   super();
//   this.inputRef=createRef();
//  // this.inputRef.current.style.color="red";
// }

// componentDidMount()
// {
//  // console.warn(this.inputRef.current.value="1000")
// }

// getVal()
// {
//   console.warn(this.inputRef.current.value)
//   this.inputRef.current.style.color="red";
//   this.inputRef.current.style.background="pink"
// }

//   render()
//   {
//     return (
   
//       <div className="App">
//       <header className="App-header">
//     <h1>React learning for Ref  </h1>
//       <h2>Count</h2>
//       <input type="text" ref={this.inputRef}  />
//      <button onClick={()=>this.getVal()}>Get Value</button>
//        </header>   
//       </div>
   
  
//    )
//   }

// }


// export default App;



// // Use memo in react functional component

// import './App.css';
// import React, {useState, useMemo} from 'react';

// function App() {
 
//   const [count, setCount]=useState(1);
//   const [item, setItem]=useState(10);

//  let multicountMemo=useMemo(function multicount()
//  {
//   console.warn("multicount")
//   return count*5
//  }, [count])

//     return (
   
//      <div className="App">
//      <header className="App-header">
//    <h1>React learning for useMemo</h1>
//      <h2>Count: {count} Item: {item}</h2>
//      <h2>Multicount: {multicountMemo}</h2>
//      <button onClick={()=>setCount(count+1)}>Update Count</button>
//      <button onClick={()=>setItem(item*10)}>Update Item</button>
//       </header>   
//      </div>
  

//   )

// }
// export default App;


// // React js send data parent to hild

// import './App.css';
// import React from 'react';
// import User from './User';

// function App() {
 

//   function parentAlert(newdata)
//   {
//     alert('Hello Anil')
//   }


//     return (
   
//      <div className="App">
//      <header className="App-header">
//    <h1>Data transfer from parent loop to child</h1>
// <User alert={parentAlert} />
//       </header>   
//      </div>
  

//   )

// }
// export default App;


// // React js send data child to parent

// import './App.css';
// import React from 'react';
// import User from './User';

// function App() {
//   let data="Anil shidu"


//     return (
   
//      <div className="App">
//      <header className="App-header">
//    <h1>Data transfer from parent loop to child</h1>
// <User name={data} />
//       </header>   
//      </div>
  

//   )

// }
// export default App;


//  // nested List with bootstrap with in react js

// import './App.css';
// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Table from 'react-bootstrap/Table';
 

// function App() {
//   const students=[
//     {name:'Anil', email:'anil@gmail.com', address: [
//       {rd:'20', hn:'50', city:'Dhaka' },
//       {rd:'25', hn:'70', city:'Chitagong' },
//       {rd:'28', hn:'59', city:'Bogra' }
//     ]},
//     {name:'Rabi', email:'rabi@gmail.com', address: [
//       {rd:'20', hn:'66', city:'Bankok' },
//       {rd:'55', hn:'76', city:'Yasi' },
//       {rd:'28', hn:'99', city:'Ginga' }
//     ]},
//     {name:'Sam', email:'sam@gmail.com', address: [
//       {rd:'34', hn:'50', city:'Delhi' },
//       {rd:'23', hn:'75', city:'Kolkata' },
//       {rd:'40', hn:'80', city:'BogrGurgoana' }
//     ]},
//     {name:'Jheel', email:'jheel@gmail.com', address: [
//       {rd:'2880', hn:'50', city:'Riadh' },
//       {rd:'25', hn:'7077', city:'Mecca' },
//       {rd:'55', hn:'33', city:'Tayeph' }
//     ]}
//   ];


//     return (
//     <>
//      <div className="App">
//      <header className="App-header">
//      <Table bordered hover variant="dark">
//     <tbody>
//   <tr>
//   <th>Sl No.</th>
//   <th>Name</th>
//   <th>Email</th>
//   <th>Address</th>
//  </tr>
//     {students.map((item, i)=> {
      
//       return (

//           <tr>
//              <td>{i+1}</td>
//             <td>{item.name}</td>
//             <td>{item.email}</td>
//             <td>
           
//              <Table bordered hover variant="dark">
//              <tbody>
             
//              <tr>
//              <th>Sl No</th>
//              <th>Road</th>
//               <th>House</th>
//               <th>City</th>
//              </tr>
//              </tbody>
//             </Table>
            
//              {item.address.map((data, n)=>
//              <Table bordered hover variant="dark">
//              <tbody>
//             <tr>
//             <td>{n+1}</td>
//             <td>{data.rd}</td>
//             <td>{data.hn}</td>
//             <td>{data.city}</td>
//             </tr>
            
//             </tbody>
//             </Table>

//             )}
          
//             </td>
//           </tr>
      
//       )
//     })}
//     </tbody>
//       </Table>

//       </header>   
// </div>
//     </>

//   )

// }
// export default App;


// // List with bootstrap with filter in react js

// import './App.css';
// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Table from 'react-bootstrap/Table';
 

// function App() {
//   const students=[
//     {name:'Anil', email:'anil@gmail.com', phone: '012345566'},
//     {name:'Rabi', email:'rabi@gmail.com', phone: '012345567'},
//     {name:'Sam', email:'sam@gmail.com', phone: '012345566'},
//     {name:'Jheel', email:'jheel@gmail.com', phone: '012345577'}
//   ];


//     return (
//     <>
//      <div className="App">
//      <header className="App-header">
//      <Table striped bordered hover variant="dark">
//     <tbody>
//   <tr>
//   <th>Name</th>
//   <th>Email</th>
//   <th>Phone</th>
//  </tr>
//     {students.map((data)=> {
      
//       return (

//         data.phone==='012345566' ?
        
//           <tr>
//             <td>{data.name}</td>
//             <td>{data.email}</td>
//             <td>{data.phone}</td>
//           </tr>:null
      
//       )
//     })}
//     </tbody>
//       </Table>

//       </header>   
// </div>
//     </>

//   )

// }
// export default App;


// //List with bootstrap in react js


// import './App.css';
// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Table from 'react-bootstrap/Table';
 

// function App() {
//   const students=[
//     {name:'Anil', email:'anil@gmail.com', phone: '012345566'},
//     {name:'Rabi', email:'rabi@gmail.com', phone: '012345567'},
//     {name:'Sam', email:'sam@gmail.com', phone: '012345566'},
//     {name:'Jheel', email:'jheel@gmail.com', phone: '012345577'}
//   ];


//     return (
//     <>
//      <div className="App">
//      <header className="App-header">
//      <Table striped bordered hover variant="dark">
//     <tbody>
//   <tr>
//   <th>Name</th>
//   <th>Email</th>
//   <th>Phone</th>
//  </tr>
//     {students.map((data)=> {
      
//       return (

      
        
//           <tr>
//             <td>{data.name}</td>
//             <td>{data.email}</td>
//             <td>{data.phone}</td>
//           </tr>
      
//       )
//     })}
//     </tbody>
//       </Table>

//       </header>   
// </div>
//     </>

//   )

// }
// export default App;


// install bootstrap in  react js  and using procedure
// import './App.css';
// import React from 'react'

// function App()
// {
  
// // const students=['Anil','Sam','Jeel'];

// const students=[
//   {name:'Anil', email:'anil@gmail.com', phone: '012345566'},
//   {name:'Rabi', email:'rabi@gmail.com', phone: '012345567'},
//   {name:'Sam', email:'sam@gmail.com', phone: '012345568'},
//   {name:'Jheel', email:'jheel@gmail.com', phone: '012345577'}
// ]


// // students.map((item)=>{
// //   console.warn("data is for map" , item)
// // });

// // for(let i=0; i<students.length; i++)

// // {
// //   console.warn("data is for loop" , students[i])
// // }


//   return (

//     <div className="App">
//       <header className="App-header">
//      <h1>This is for map data</h1>
//   <table>
//     students.map(function(data)
//      <tr>
//       <td>Name: {data.name}</td>
//     </tr>

//     )
   
//   </table>
      
//       </header>
//     </div>
//   );
// }

// export default App;







// // install bootstrap in  react js  and using procedure
// import './App.css';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import Card from 'react-bootstrap/Card';

// // or less ideally
// import { Button } from 'react-bootstrap';

// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';

// function App()
// {
  
//   return (

//     <div className="App">
//       <header className="App-header">
     
//       <Card style={{ width: '18rem' }}>
//       <Card.Img variant="top" src="holder.js/100px180" />
//       <Card.Body>
//         <Card.Title>Card Title</Card.Title>
//         <Card.Text>
//           Some quick example text to build on the card title and make up the
//           bulk of the card's content.
//         </Card.Text>
//         <Button variant="primary">Go somewhere</Button>
//       </Card.Body>
//     </Card>

//       <>
//       <Button variant="primary" size="lg" active>
//         Primary button
//       </Button>{' '}
//       <Button variant="secondary" size="lg" active>
//         Button
//       </Button>
//     </>
//     <Tabs
//       defaultActiveKey="profile"
//       id="uncontrolled-tab-example"
//       className="mb-3"
//     >
//       <Tab eventKey="home" title="Home">
//         Tab content for Home
//       </Tab>
//       <Tab eventKey="profile" title="Profile">
//         Tab content for Profile
//       </Tab>
//       <Tab eventKey="contact" title="Contact" disabled>
//         Tab content for Contact
//       </Tab>
//     </Tabs>
      
//       </header>
//     </div>
//   );
// }

// export default App;


// // Using style 3 process in React
// import './App.css';
// import './custom.css'
// import style from './custom.module.css'
// function App()
// {
  
//   return (

//     <div className="App">
//       <header className="App-header">
//      <h1 className="success">React Learning Style Method One</h1>
//      <h1 style={{color:'blue', backgroundColor:'yellow'}}>React Learning Style Method Two inline style</h1>
//      <h1 className={style.succeed}>React Learning Style Method Three</h1>
      
//       </header>
//     </div>
//   );
// }

// export default App;




// // useEffect  with  props in react js  

// import './App.css';
// import React,{useEffect, useState} from 'react'

// import User from './User'

// function App()

// {
// const[count,setCount]=useState(12)
// const[data,setData]=useState(40)


//   return (

//     <div className="App">
//       <header className="App-header">
        
//         <User count={count} data={data} />
         
//          <button onClick={()=>setCount(count+1)}>Update Count</button>
//          <button onClick={()=>setData(data+1)}>Update Data</button>
//       </header>
    
//     </div>
//   );

  
// }

// export default App;




// // useEffect  with state in react js  

// import './App.css';

// import React,{useEffect, useState} from 'react'

// function App()

// {
// const[count,setCount]=useState(12)
// const[data,setData]=useState(40)
// useEffect(()=>{

//   console.warn('useeffect for data')

// },[data])

// useEffect(()=>{

//   alert('useeffect for count'+ count)

// },[count])

//   return (

//     <div className="App">
//       <header className="App-header">
//         <h1>React Count content {count}<br />React data content {data}</h1>
         
//          <button onClick={()=>setCount(count+1)}>Update Count</button>
//          <button onClick={()=>setData(data+1)}>Update Data</button>
//       </header>
    
//     </div>
//   );

  
// }

// export default App;




// // useEffect hook in react js  

// import './App.css';

// import React,{useEffect, useState} from 'react'

// function App()

// {
// const[count,setCount]=useState(1)
// useEffect(()=>{

//   console.warn('useeffect')

// })

//   return (

//     <div className="App">
//       <header className="App-header">
//         <h1>React content {count}</h1>
//          <button onClick={()=>setCount(count+1)}>Update</button>
//       </header>
//     </div>
//   );

  
// }

// export default App;




// // Conditional if/else use with tarnery operator in react tutorial  

// import './App.css';

// import React from 'react'

// function App()

// {
// const[loggedIn,setLoggedIn]=React.useState(3)

//   return (

//     <div className="App">
//       <header className="App-header">
//         <h1>Conditional content</h1>
//      {
//      loggedIn==1 ?
//       <h1>You are welcome</h1>
//      : loggedIn==2 ?
//       <h1>You are Editor</h1>
//       :<h1>You are Guest</h1>

//      }

//       </header>
//     </div>
//   );

  
// }

// export default App;



// // basic form tutorial  

// import './App.css';

// import React from 'react'

// function App()

// {
// const[name,setName]=React.useState("");
// const[interest,setInterest]=React.useState("");
// const[tnc,setTnc]=React.useState(false);


//   function getData(e)
//   {
//     console.warn(name,interest,tnc)
//     e.preventDefault()
//   }

//   return (

//     <div className="App">
//       <header className="App-header">
//         <h1>Basic React Form</h1>
//        <form onSubmit={getData}>
//        <input type="text" placeholder="Name option" onChange={(e)=>setName(e.target.value)} /><br /><br />
//        <select onChange={(e)=>setInterest(e.target.value)}>
//        <option>Select Option</option>
//        <option>Large</option>
//        <option>Medium</option>
//        <option>None</option>

//        </select><br /><br />
//        <input type="checkbox" onChange={(e)=>setTnc(e.target.checked)} /><span>I agree terms and condition</span><br /><br />
//        <button type="submit">Submit</button>

//       </form>

//       </header>
//     </div>
//   );

  
// }

// export default App;




// // show and hide element (toggle) 

// import './App.css';

// import React from 'react'

// function App()

// {

//   const[status,setStatus]=React.useState(true)

//   return (

//     <div className="App">
//       <header className="App-header">
//        {

//        status?
//        <h1>Hello Everyone</h1> 
//        : null

//        }
//          {/* for both hide show button */}
//        {/* <button onClick={()=>setStatus(true)}>Show</button>
//         <button onClick={()=>setStatus(false)}>Hide</button> */}

//          {/* for toggle use in single button */}
//           <button onClick={()=>setStatus(!status)}>Toggle</button>
//       </header>
//     </div>
//   );

  
// }

// export default App;





// // get input box value 

// import './App.css';

// import React, {useState} from 'react'

// function App()

// {

//   const[data,setData]=useState(null)
//   const[print,setPrint]=useState(false)

// function getData(val)
// {

//   console.warn(val.target.value)
//   setData(val.target.value)
//   setPrint(false)
// }

//   return (

//     <div className="App">
//       <header className="App-header">
//        {

//        print?
//        <h1>{data}</h1> 
//        : null

//        }
         
//       <input type="text" onChange={getData} />

//        <button onClick={()=>setPrint(true)}>Print Me</button>
//       </header>
//     </div>
//   );

  
// }

// export default App;



// props in class component

// // import {Component} from 'react'
// import './App.css';
// // import User from './User';
// import Student from './Student';
// import React from 'react'

// class App extends React.Component
// {

//   constructor()
//   {
//     super();
//     this.state={
//       name: "Anil"
//     }
//   }

// render()
// {
//   return (

//     <div className="App">
//       <header className="App-header">
       
//         <h1>This is react class</h1>  
//        <Student name={this.state.name} />

//        <button onClick={()=>this.setState({name:'Shidu'})}>Update Me</button>
//       </header>
//     </div>
//   );
// }
  
// }

// export default App;




// function App()
// {
  
//   return (

//     <div className="App">
//       <header className="App-header">
       
//         <h1>This is react class</h1>  
//        <Student name="Anil Ahmed" />
//       </header>
//     </div>
//   );
// }

// export default App;















// state in class component in react

// class App extends Component
// {

//   constructor()
//   {
//     super();
//     this.state={
//       data:"Anil"
//   }
//   }

//   apple()
//   {
//     this.setState({data:"Shidu"})
//   }

//  render()
//  {




//   return (

//     <div className="App">
//       <header className="App-header">
       
//         <h1>{this.state.data}</h1>  
     
//        <button onClick={()=>this.apple()}>Click Me</button>
//       </header>
//     </div>
//   );
// }

// }

// export default App;



import React from 'react';
const Contact = () => {
   
 return (
    <>
    <h1 style={{color:"green"}}> 
        This is a Contact Page.</h1>
  </>
  )
};
  
export default Contact;

